import axios from 'axios';
import { onResponseError } from '../common/responseErrorHandler';
import { onRequestError } from '../common/requestErrorHandler';
import { onRequestAppendAcceptLanguageHeader, onRequestAppendApiKey, onRequestAppendApp, onRequestAppendBrowserDetails, onRequestAppendLocalStorageGuestIdentity, onRequestAppendMagicKey, onRequestAppendPrioneerTerminalKey, onRequestAppendYoioToken } from '../common/requestAppendToken';
import { isHavingValue, notNullNotUndefined } from 'utils/objectUtils';
import { notify } from 'modules/yoio/errorsService';
import { YoioHttpRequestFields } from 'modules/yoio/model/YoioHttpRequestFields';
import { paramsToQueryString } from 'utils/urlUtils';
import { AccessEvent } from 'modules/yoio/YoioTypes';
import { canRefreshCredential, isCredentialExpiredOrExpiresSoon, isRefreshingCredential } from 'modules/react-auth/currentUser';
import { createClientSideApiBaseUrl } from 'apiClient/utils';
import { refreshAccessToken, refreshAccessTokenAttemptOrRequireLogin } from 'modules/react-auth';

let instanceDefaultCalls = null;
let instanceBackgroundCalls = null;

let instanceInternalCalls = null;

const isEmbedded = () => {
  //console.log('isEmbedded p1',typeof window !== 'undefined')
  //console.log('isEmbedded p2',window.yoioContext?.pageSettings?.embedded === true)
  //console.log('isEmbedded debug',window.yoioContext)

  return typeof window !== 'undefined' && window.yoioContext?.pageSettings?.embedded === true
}

// For client side requests with credentials and interceptors.

export const getAxiosDefault = () => {
  if (instanceDefaultCalls === null) {
    if (isEmbedded()) { //embedded without credentials; might change
      instanceDefaultCalls = createClientSideInstanceToApi({
        withCredentials: false,
        requestAppendYoioData: true,
        refreshExpiredToken: true,
        responseErrorInterceptor: true,
      })
    } else {
      instanceDefaultCalls = createClientSideInstanceToApi({
        withCredentials: true,
        requestAppendYoioData: true,
        refreshExpiredToken: true,
        responseErrorInterceptor: true,
      })
    }
  }

  return instanceDefaultCalls;
}

// For client side requests, but errors will not show an error alert in the ui

export const getAxiosBackground = () => {
  if (instanceBackgroundCalls === null) {
    if (isEmbedded()) { //embedded without credentials; might change
      instanceBackgroundCalls = createClientSideInstanceToApi({
        withCredentials: false,
        requestAppendYoioData: true,
        refreshExpiredToken: true,
        responseErrorInterceptor: false,
      })
    } else {
      instanceBackgroundCalls = createClientSideInstanceToApi({
        withCredentials: true,
        requestAppendYoioData: true,
        refreshExpiredToken: true,
        responseErrorInterceptor: false,
      })
    }
  }

  return instanceBackgroundCalls;
}

let waitingQueue = []

/* export const processWaitingRequestsQueue = () => {
  console.log('processWaitingRequestsQueue')
  const proms = waitingQueue.map(({instance, config}) => {
    config = {
      ...config
    }
    config.params = config.params || {}
    config.params.delayed = true
    return instance(config)
  })
  waitingQueue = []
  Promise.all(proms).then()
}; */

export const processWaitingRequestsQueue = () => {
  console.log('processWaitingRequestsQueue')
  const proms = [...waitingQueue]
  waitingQueue = []
  proms.forEach(({resolve})=>{
    resolve()
  })
};

export const clearWaitingRequestsQueue = () => {
  waitingQueue = []
}

const createClientSideInstanceToApi = (options) => {
  notNullNotUndefined(options)

  const { withCredentials, requestAppendYoioData, refreshExpiredToken, responseErrorInterceptor } = options

  const apiUrl = createClientSideApiBaseUrl()

  const instance = axios.create({
    baseURL: apiUrl,
    paramsSerializer: (params)=>{
      if (!params) {
        return ''
      }
      return paramsToQueryString(params)
    },
  })

  if (isHavingValue(withCredentials)) {
    instance.defaults.withCredentials = withCredentials
  }

  if (requestAppendYoioData) {
    instance.interceptors.request.use((config)=>{

      config = onRequestAppendMagicKey(config)
      config = onRequestAppendApiKey(config)
      config = onRequestAppendAcceptLanguageHeader(config)
      config = onRequestAppendBrowserDetails(config)
      try {
        config = onRequestAppendYoioToken(config)
      } catch (error) {
        notify(error)
      }
      try {
        config = onRequestAppendApp(config)
      } catch (error) {
        notify(error)
      } 
      try {
        config = onRequestAppendLocalStorageGuestIdentity(config)
      } catch (error) {
        notify(error)
      } 
      try {
        config = onRequestAppendPrioneerTerminalKey(config)
      } catch (error) {
        notify(error)
      }
      return config;
    }, onRequestError);
  }

  if (refreshExpiredToken) {

    instance.interceptors.request.use(async (config)=>{

      if (isRefreshingCredential()) {
        console.log('queue request ' + config.url)

        //debug
/*         config.params = {
          ...config.params,
          deferred: true,
        } */

        const deferredConfigPromise = new Promise((resolve, reject)=>{
          // Don't resolve here, but push resolve to waiting queue
          waitingQueue.push({resolve, reject})
        }).then(()=>{
          // Return config as soon as resolves
          return config
        })

        return deferredConfigPromise
      }

      if (canRefreshCredential() && isCredentialExpiredOrExpiresSoon()) {
        // Passing config to refreshAccessToken is not necessary because it does not need to know any user credentials.
        // refreshAccessToken is responsible itself to attach a refresh token.
       // console.debug('Refreshing at before request - start')
        try {
          await refreshAccessTokenAttemptOrRequireLogin()
          //console.debug('Refreshing at before request - done')
/*           config.params = {
            ...config.params,
            afterRefresh: true,
          } */
          console.log('execute request (after refresh) ' + config.url + ' ' + (config.params?paramsToQueryString(config.params):''))
        } catch (error) {
          console.error(error)
        }
      } else {
        //console.debug('execute request (regular) ' + config.url + ' ' + (config.params?paramsToQueryString(config.params):''))
      }

      return config
    }, (error)=>error)

/*     instance.interceptors.response.use((response)=>response, (error) =>{
      const lastAuthUser = getLastAuthenticatedUser()

      // If YOIO credential, then try refresh
      if (lastAuthUser && lastAuthUser.credential?.issuerId === IssuerId.YOIO) {
        retryRefreshToken(error, instance)
      }
      
      return Promise.reject(error)
    }) */
  }

  if (responseErrorInterceptor) {
    instance.interceptors.response.use(function (response) {
      //do nothing
      return response;
    }, onResponseError);
  }

  return instance
}

const retryRefreshToken = (error, retryInstance) => {
  const config = error.config
  const status = error.response?.status
  if (status === 403 || status === 401) {
    // Use global axios
    // withCredentials must be true so that set-cookie from server is used.
    return refreshAccessToken()
      .then(()=>{
        return new Promise((resolve) => {
            resolve(retryInstance(config))
        })
      })
      .catch((error)=>{
        handleRefreshError(error)
      })
  }

  return Promise.reject(error)
}

const handleRefreshError = (error) => {
  const status = error.response?.status
  if (status === 401) {
    let event = new Event(AccessEvent.userLoginRequired)
    window.dispatchEvent(event)
  } else {
    notify('error refreshing credential')
    throw new Error(error)
  }
}

/**
 * 
 * @param {*} host 
 *    Must be passed if its a server side request.
 *    The target host. If passed, its used. If not passed, then host from window.location.hostname is taken.
 * @returns 
 */
export const createAppSpecificInstanceToApi = (host) => {

  if (!host) {
    if (typeof window === 'undefined') {
      throw new Error('error in createAppSpecificInstanceToApi. no host passed and window is undefined.')
    }
    host = window.location.hostname;
  }

  const baseUrlTemplate = process.env.NEXT_PUBLIC_APP_API_BASE_URL_CLIENT
  const baseUrl = baseUrlTemplate.replace('{host}', host)

  let instance = axios.create({
    baseURL: baseUrl,
  });
  instance.defaults.withCredentials = false;
  return instance;
}

export const getInstanceInternal = () => {
  if (!instanceInternalCalls) {
    instanceInternalCalls = axios.create({
      baseURL: process.env.APP_API_BASE_URL_INTERNAL,
    });
  }
  
  return instanceInternalCalls;
}

/**
 * 
 * This instance is supposed for server side calls from next.js, for example in getServerSideProps.
 * 
 * It is intended to be used to preload data for the page, form the perspective of the user.
 * 
 * It aims to behave exactly as the client side instance (but it is actually not currently!), that means it ideally:
 * - it must forward the user session
 * - it must forward magic keys
 * - it must forward yoioToken
 * 
 * otherwise the behavior in pages that use this will be not right
 * 
 * 
 * @param {*} context 
 * @param {*} locale 
 * @returns 
 */
export const getInstanceForContext = (context, locale) => {
  notNullNotUndefined(context)
  let uiHost = context.req.headers.host

  const headers = {}

  //currently these headers are never actually available in getServerSideProps because the UI pre-initialises them
  //so this is not sufficient to work
  //YoioHttpRequestFields.HEADER_CONTEXT_TOKEN,YoioHttpRequestFields.HEADER_GUEST_IDENTITY, YoioHttpRequestFields.HEADER_MKEY]

  if (context.req.headers.cookie) {
    headers.cookie = context.req.headers.cookie
  }

  //forward the yoioToken query param as header
  if (context.query && context.query[YoioHttpRequestFields.PARAM_YOIO_TOKEN]) {
    headers[YoioHttpRequestFields.HEADER_YOIO_TOKEN] = context.query[YoioHttpRequestFields.PARAM_YOIO_TOKEN]
  }

  let apiHostDomain = uiHost + ''
  if (apiHostDomain.includes(':')) {
    apiHostDomain = apiHostDomain.split(':')[0];
  }

  const apiHostTemplate = process.env.APP_API_BASE_URL_INTERNAL_HEADER_HOST
  const apiHost = apiHostTemplate.replace('{host}', apiHostDomain)

  headers['Host'] = apiHost

  if (locale) {
    headers['Accept-Language'] = locale
  }
  
  return axios.create({
    baseURL: process.env.APP_API_BASE_URL_INTERNAL,
    headers
  });
}

export const internalGet = async (path, uiHost, locale) => { //uiHost will be used to create apiHost from it
  if (!path) {
    throw new Error('path must have a value')
  }
  if (!uiHost) {
    throw new Error('uiHost must have a value')
  }

  const instance = getInstanceInternal()

  const headers = {}

  let apiHostDomain = uiHost + ''
  if (apiHostDomain.includes(':')) {
    apiHostDomain = apiHostDomain.split(':')[0];
  }

  const apiHostTemplate = process.env.APP_API_BASE_URL_INTERNAL_HEADER_HOST
  const apiHost = apiHostTemplate.replace('{host}', apiHostDomain)

  headers['Host'] = apiHost

  if (locale) {
    headers['Accept-Language'] = locale
  }

  const res = await instance.get(path, {
    headers
  });
  return res
}