import { PrioneerHttpRequestFields } from "modules/plinzip/api/PrioneerHttpRequestFields";
import { getCurrentTerminalKey } from "modules/plinzip/views/rankings/answering/currentTerminalKey";
import { getApiKey, getMagicKey } from "modules/react-auth/currentUser";
import { getFromLocalStorageById, getFromSessionStorageById } from "modules/yoio-modules/storage/clientSideStore/clientSideStore";
import { YoioHttpRequestFields, YoioSessionStorageFields } from "modules/yoio/model/YoioHttpRequestFields";
import { getMyLocale } from "modules/yoio/myLocaleService";
import { notNullNotUndefined } from "utils/objectUtils";
import { encodeBase64 } from "utils/stringUtils";
import { getQueryParamSafe } from "utils/windowUtils";

export const onRequestAppendPrioneerTerminalKey = function (config) {
  const terminalKey = getCurrentTerminalKey()
  if (terminalKey) {
    config.headers[PrioneerHttpRequestFields.HEADER_TERMINALKEY] = terminalKey
  }
  return config
}

export const onRequestAppendBrowserDetails = function (config) {

  let screenSize;
  let windowSize;
  let timezone;

  try {
    screenSize = `${screen.width}x${screen.height}`
  } catch (error) {
    // Silent
  }

  try {
    windowSize = `${window.innerWidth}x${window.innerHeight}`
  } catch (error) {
    // Silent
  }

  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (error) {
    // Silent
  }

  const details = {} as any;
  if (screenSize) { details.ss = screenSize}
  if (windowSize) { details.ws = windowSize}
  if (timezone)   { details.tz = timezone}

  let detailsStr = Object.keys(details).map(k=>`${k}:${details[k]}`).join(';')
  if (detailsStr) {
    config.headers[YoioHttpRequestFields.HEADER_BROWSER_DETAILS] = detailsStr;
  }

  return config;
}

export const onRequestAppendMagicKey = function (config) {
    const mkey = getMagicKey();
    if (mkey) {
      config.headers[YoioHttpRequestFields.HEADER_MKEY] = mkey;
    }
  return config;
}

export const onRequestAppendApiKey = function (config) {
  const val = getApiKey();
  if (val) {
    config.headers[YoioHttpRequestFields.HEADER_API_KEY] = val;
  }
return config;
}
export const onRequestAppendYoioToken = function (config) {
  onRequestAppendYoioTokenV2(config)
  return config;

/*   let yoioToken = getQueryParamSafe(YoioHttpRequestFields.PARAM_YOIO_TOKEN)
  if (yoioToken) {
    config.params = config.params || {};
    config.params.yoioToken = yoioToken;
  }
  return config; */
}

export const onRequestAppendYoioTokenV2 = function (config) {
  let yoioToken = getFromSessionStorageById(YoioSessionStorageFields.ACCESS_YT)
  if (yoioToken) {
    config.headers[YoioHttpRequestFields.HEADER_YOIO_TOKEN] = yoioToken
  }
  return config;
}

export const onRequestAppendApp = function (config) {
  let app = getQueryParamSafe('app')
  if (app) {
    config.params = config.params || {};
    config.params.app = app;
  }
  return config;
}

export const onRequestAppendLocalStorageGuestIdentity = function (config) {
  let guestIdentity = getFromLocalStorageById('gid')
  if (guestIdentity) {
    config.headers[YoioHttpRequestFields.HEADER_GUEST_IDENTITY] = encodeBase64(JSON.stringify(guestIdentity))
  }
  return config;
}

export const onRequestAppendAcceptLanguageHeader = function (config) {

  let myLocale;
  
  try {
    myLocale = getMyLocale()
  } catch (error) {
    //can fail in sandboxed iframe
  }

  if (config.url?.startsWith('/access/ev')) {
    // Do nothing to keep access language header
    return config
  }

  if (myLocale) {
    config.headers['Accept-Language'] = myLocale;
  } else {
    // Fixed now until language select added (en must also be changed in getServerSide calls)
    config.headers['Accept-Language'] = 'en';
  }

  return config;
};

export const onXMLHttpRequest = function (request) {
  notNullNotUndefined(request)

  // Credentials
  request.withCredentials = true

  // Yoio token
  let yoioToken = getFromSessionStorageById(YoioSessionStorageFields.ACCESS_YT)
  if (yoioToken) {
    request.setRequestHeader(YoioHttpRequestFields.HEADER_YOIO_TOKEN, yoioToken)
  }

  // Locale
  let myLocale;
  
  try {
    myLocale = getMyLocale()
  } catch (error) {
    //can fail in sandboxed iframe
  }

  if (myLocale) {
    request.setRequestHeader('Accept-Language', myLocale)
  }
}