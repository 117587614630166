import { notNullNotUndefined } from "utils/objectUtils";
import { isEmpty } from "utils/stringUtils";

export function setCookie(name, value) {
    document.cookie = name + '=' + (value || '') + '; max-age=31536000; path=/';
}

export function getCookie(name) {
    notNullNotUndefined(name)
    return parseCookie(name, document.cookie)
}

export function parseCookie(name, allCookiesString) {
    notNullNotUndefined(name)

    if (isEmpty(allCookiesString)) {
        return null;
    }

    var nameEQ = name + '=';
    var ca = allCookiesString.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function eraseCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
