import { useIsMounted } from "modules/picasso-ui/form/useIsMounted";
import { createContext, useEffect } from "react";
import alerts from "modules/yoio/state/alerts";
import { isHavingValue } from "utils/objectUtils";
import { loadMe } from ".";

export const MeContext = createContext({
    me: null,
    signedIn: null,
    user: null,
    loaded: false
  });

export const MeContextProvider = ({children, lazy, loadingElement, signInAllowed, signInForce}) => {

    const isMounted = useIsMounted()
  
    if (!isHavingValue(lazy)) {
      lazy = false;
    }
  
    const { meResult, isLoadingInitially, loadingError } = loadMe({lazy, signInAllowed, signInForce});
    
    useEffect(()=>{
      if (isMounted()) {
        if (loadingError === true) {
          alerts.error('Connectivity issues. Please refresh page.')
        }
      }
    },[loadingError])
  
    //console.debug'meResult', meResult)
  
    return (
      <>
        {meResult && !loadingError ?
            <>
              <MeContext.Provider value={meResult}>
                {children}
              </MeContext.Provider>
            </>
          :
            <>
              {loadingElement 
              && (isLoadingInitially === true || loadingError === true) 
              && (
                <>{loadingElement}</>
              )}
            </>
          }
      </>
    )
  
  }