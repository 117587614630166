export const YoioHttpRequestFields = {
   HEADER_MKEY: 'x-yoio-mkey',
   HEADER_GUEST_IDENTITY: 'x-yoio-gid',
   HEADER_CONTEXT_TOKEN: 'x-yoio-context-token',
   HEADER_YOIO_TOKEN: 'x-yoio-yoiotoken',
   HEADER_LAST_USER: 'x-yoio-last-user',
   HEADER_BROWSER_DETAILS: 'x-yoio-brd',
   HEADER_API_KEY: 'apikey',
   PARAM_YOIO_TOKEN: 'yoioToken',
   PARAM_MKEY: 'mkey',
}

export const YoioSessionStorageFields = {
   ACCESS_YT: 'access_yt'
}