import { getCookie, setCookie } from "modules/yoio-modules/storage/cookie/cookie"
import { notNullNotUndefined } from "utils/objectUtils"


export const setMyLocale = (locale) => { //str
    notNullNotUndefined(locale)
    setCookie('access_locale', locale)
}

export const getMyLocale = () => {
    return getCookie('access_locale')
}

export const isMyLocaleReady = () => {
    if (window?.localStorage) {
        return true
    } else {
        return false
    }
}