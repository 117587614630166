import { getAxiosBackground, getAxiosDefault } from 'apiClient/config/apiAxiosConfig';
import { notNullNotUndefined } from 'utils/objectUtils';

export const apiSignIn = (additionalConfig, headers) => getAxiosDefault()({
  url: `/access/signIn`,
  method: 'POST',
  additionalConfig,
  headers
})

export const apiSignInAlias = (data) => getAxiosBackground()({
  url: `/access/signInAlias`,
  method: 'POST',
  data: data,
})

export const apiSignOut = () => getAxiosBackground().post(`/access/signOut`)

export const getMe = (traceId, headers) => {
  let params = null
  if (traceId) {
    params = params || {}
    params.traceId = traceId
  }
  return getAxiosDefault()(`/access/me`, {params, headers}).then((response) => {
    return response.data;
  })
}

export const getMeBackground = (traceId, headers) => {
  let params = null
  if (traceId) {
    params = params || {}
    params.traceId = traceId
  }
  return getAxiosBackground()(`/access/me`, {params, headers})
}

export const terminateMe = () =>
  getAxiosDefault().post(`/access/me/terminate`);

export const getWorkspace = (workspaceId) =>
  getAxiosDefault()(`/access/workspaces/${workspaceId}`).then((response) => {
    return response.data;
  });

export const changeWorkspace = (workspaceId, data) => {
  notNullNotUndefined(workspaceId)
  notNullNotUndefined(data)
  return getAxiosDefault().patch(`/access/workspaces/${workspaceId}`, data)
}

export const signup = (data) =>
  getAxiosDefault().post(`/access/signup`, data);

export const signupEmail = (data) => getAxiosDefault()({
    url: `/access/signupEmail`,
    method: 'POST',
    data: data,
})

export const joinWorkspace = (data) => getAxiosDefault()({
    url: `/access/joinWorkspace`,
    method: 'POST',
    data: data,
})

export const saveAccessEvent = (str, data) => getAxiosBackground().post(`/access/ev/${str}`, data);

export const saveAccessEventUser = (str, data) => getAxiosBackground().post(`/access/ev/${str}/user`, data);

export const getUserAccountSettings = (userAccountId) =>
  getAxiosDefault().get(`/access/users/${userAccountId}/settings`);

export const changeGuestIdentity = ({name, setCookieOnResponse}) => {
  notNullNotUndefined(name)

  return getAxiosBackground()({
    url: `/access/me/changeGuestIdentity`,
    method: 'POST',
    data: {name, setCookieOnResponse},
  }).then(res=>res.data)
}

export const createGuestIdentity = () => {
  return getAxiosBackground()({
    url: `/access/me/createGuestIdentity`,
    method: 'POST',
  }).then(res=>res.data)
}

export const clearGuestIdentity = () => {
  return getAxiosBackground()({
    url: `/access/me/clearGuestIdentity`,
    method: 'POST',
  })
}

export const passwordReset = ({alias}) => {
  notNullNotUndefined(alias)
  return getAxiosDefault().post(`/access/passwordReset`, {alias})
}

export const passwordResetCheckToken = (token) => {
  notNullNotUndefined(token)
  const headers = {
    'x-yoio-context-token': token
  }
  return getAxiosDefault().post(`/access/passwordReset/checkToken`, null, {headers})
}

export const passwordResetChange = (token, password) => {
  notNullNotUndefined(token)
  notNullNotUndefined(password)
  const headers = {
    'x-yoio-context-token': token
  }
  return getAxiosDefault().post(`/access/passwordReset/change`, {password}, {headers})
}

export const emailConfirmationMailSend = () => {
  return getAxiosDefault().post(`/access/me/emailConfirmationMailSend`)
}

export const getUsageCodeAfterSignup = (reference) => getAxiosDefault()(`/access/usageCodeAfterSignup`, { params: { reference } })

export const getUsageCodeAfterSignupBackground = (reference) => getAxiosBackground()(`/access/usageCodeAfterSignup`, { params: { reference } })

export const dismissNotification = (notificationId) =>  getAxiosDefault().post(`/access/me/notifications/${notificationId}/dismiss`)