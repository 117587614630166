import { notNullNotUndefined } from "./objectUtils";

export const isNumber = (value) => {
    return typeof value === 'number' && isFinite(value)
}

export const round = (number, digits) => {
    return (Math.round(number * 100)/100).toFixed(digits)
}

export const roundSafe = (number, digits) => {
    try {
        return (Math.round(number * 100)/100).toFixed(digits);
    } catch (e) {
        console.error(e)
        return number
    }
}

export const toCleanPercentage = (number) => {
    return Math.min(roundSafe(number, 0), 100)
}

export const formatMaximumTwoDecimals = (value) => {
    notNullNotUndefined(value)
    let truncated = Math.trunc(value * 100) / 100;
    if (truncated+''.length > 4) {//catch javascript missbehaviors
      return truncated+''.substr(0,5)
    }
    return truncated
}


export const isStringNumeric = (str) => {
    if (typeof str != "string") return false
    return !isNaN(str) &&
           !isNaN(parseFloat(str)) 
}

export const countDecimals = (str) => {

    if (Math.floor(str) === str) return 0;

    if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
        return str.split("-")[1] || 0;
    } else if (str.indexOf(".") !== -1) {
        return str.split(".")[1].length || 0;
    }
    return str.split("-")[1] || 0;
}

export const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
}
  