
let clientSideApiBaseUrl = null

export const createClientSideApiBaseUrl = () => {
  if (clientSideApiBaseUrl != null) {
    return clientSideApiBaseUrl
  }
  if (typeof window !== 'undefined') {
    const host = window.location.hostname;

    //let params = (new URL(document.location)).searchParams;
    //let apiHost = params.get("apiHost");
    //if (apiHost) {
    //  host = apiHost
    //}

    const baseUrlTemplate = process.env.NEXT_PUBLIC_APP_API_BASE_URL_CLIENT

    clientSideApiBaseUrl = baseUrlTemplate.replace('{host}', host)

    return clientSideApiBaseUrl
  } else {
    throw new Error('unexpected call of createClientSideInstanceToApi. window is undefined.')
  }
}