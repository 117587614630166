import { notify } from 'modules/yoio/errorsService';

let firebaseApp;
let loading = false

export default function initFirebase(authDomain) {
  if (firebaseApp) {
    loading = false
    return Promise.resolve(firebaseApp)
  }

  if (loading) {
    while (loading) {
      // noop
    }
    return Promise.resolve(firebaseApp);
  }
  loading = true

  if (!authDomain && typeof window !== undefined && window.yoioContext?.app.browserAuthDomain) {
    authDomain = window.yoioContext?.app.browserAuthDomain
  }

  if (!authDomain) {
    throw new Error('browserAuthDomain required')
  }
    
  const config = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_APP_API_KEY,
    authDomain: authDomain,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_APP_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_APP_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_APP_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_APP_MEASUREMENT_ID
  }

  console.debug('initFirebase')

  return import('firebase/app').then((mod)=>{
    firebaseApp = mod.initializeApp(config)
    loading = false
    return firebaseApp
  })
    
}

export const getFirebaseApp = () => {
  return firebaseApp;
}