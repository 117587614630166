import { buttonClasses, darken, lighten, listItemClasses, listItemIconClasses, outlinedInputClasses, svgIconClasses } from '@mui/material';
import { grey } from '@mui/material/colors';
import { strToGradientLinear } from 'utils/cssUtils';
import { pick } from 'utils/objectUtils';

/* const gradientToDark = (darkColor) => {
  return 'linear-gradient(top, '+lighten(darkColor,0.2)+' 0%, '+darkColor+' 100%)'
} */

const customProps = {
  popoverBoxShadow: 'rgb(0 0 0 / 5%) 0px 6px 6px, rgb(0 0 0 / 5%) 0px -1px 6px, rgb(0 0 0 / 5%) 0px 10px 20px',
}

const createPalette = (basePalette) => {

 const {primary, secondary, gradient, textmarker, primaryGradientLinear, background } = basePalette || {}

 const primaryGradientLinearEffective = primaryGradientLinear?.main ? strToGradientLinear(primaryGradientLinear.main, 90) : null

 const palette = {
    primary: {
      main: '#005fff',
      light: lighten('#005fff', 0.3),
      contrastText: '#fff',
      background: '#edf5ff',
      ...primary,
    },
    primaryGradientLinear: {
      main: primaryGradientLinearEffective,
      light: primaryGradientLinearEffective,
      contrastText: '#fff',
    },
    secondary: {
      main: 'rgb(220, 0, 78)',
      ...secondary
      //main: '#DEF2F0',
    },
    greyLight: {
      main: '#7a858f',
      dark: grey[400]
    },
    greyDark: {
      main: '#323e52',
      medium: '#87909d',
    },
    attention: {
      main: '#f64585',
      light: '#f64585',
      dark: '#f64585',
      contrastText: '#fff',
    },
    text: {
      primary: basePalette?.text?.primary || '#263545', //'#14203a',
      secondary: '#646e8e', //'#646f91', //'#6a7483',
      disabled: lighten('#8d9bb0', 0.25),
    },
    textmarker,
    divider: '#f2f2f2', //'#dcdce7',
    action: {
      active: 'rgba(0, 0, 0, 0.54)',//'rgb(247, 247, 248)',
      hover: 'rgb(247, 247, 248)',
    },
    gradient: gradient || 'linear-gradient(to right, #eff4f9 0%, #d8e9f9 100%)',
    gradientSecondaryDark: 'linear-gradient(317deg, var(--palette-secondary-main) 10%, #6874d7 25%,  var(--palette-secondary-main) 53%, var(--palette-secondary-main) 100%)',
  };

  if (background) {
    palette.background = background
  }

  return palette
}

export const createThemeConfig = (themeConfigBase) => {

  const paletteEffective = createPalette(themeConfigBase?.palette)

  let allHeadingsStyles = themeConfigBase?.typography?.allHeadings ? pick(themeConfigBase?.typography?.allHeadings, 'letterSpacing', 'fontFamily', 'textTransform', 'fontWeight') : null

  if (themeConfigBase?.typography?.fontFamily) {
    allHeadingsStyles = allHeadingsStyles || {}
    if (!allHeadingsStyles.fontFamily) {
      allHeadingsStyles.fontFamily = themeConfigBase?.typography?.fontFamily
    }
  }
  
  let typography = {
    fontSize: 14,
    fontFamily: themeConfigBase?.typography?.fontFamily || `'Inter', sans-serif`,
    allVariants: {
      fontSize: 14,
      fontFamily: themeConfigBase?.typography?.fontFamily || `'Inter', sans-serif`,
      //color: paletteEffective.text.primary,
    },
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
/*     body1: {
      fontSize: 14,
      fontFamily: `'Inter', sans-serif`,
    },   */
    h1: {
      fontSize: '4.2em',
      fontWeight: 800,
      color: paletteEffective.text.primary,
      ...allHeadingsStyles,
    },
    h2: {
      color: paletteEffective.text.primary, //'#040b19',//'rgb(19 32 55)',//'rgb(46 74 123)', //darken(paletteEffective.primary.main, 0.8), //#001634,
      fontSize: '2.7em',
      fontWeight: 600,
      lineHeight: 1.4,
      ...allHeadingsStyles,
    },
    h3: {
      fontSize: '1.1rem',
      fontWeight: '500',
      //color: darken(paletteEffective.primary.main, 0.8),
      color: paletteEffective.text.primary,
      ...allHeadingsStyles,
      ...themeConfigBase?.typography?.h3,
    },
    h4: {
      fontSize: 16,
    },
    h5: {
      fontSize: 13,
      color: '#3c4a5ade',
    },
  }
  
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    typography,
    palette: paletteEffective,
    components: {
      MuiCssBaseline: {
        styleOverrides:`
          html {
            text-rendering: optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          body: {
            backgroundColor: #fff;
          }
        `
      },
      MuiTypography: {
        variants: [
          {
            props: {color: "greyDark" },
            style: {
              color: paletteEffective.greyDark.main,
            }
          },
        ]
      },
      MuiLink: {
        styleOverrides: {
          underlineHover: {
            '&:hover': {
              textDecoration: 'none'
            }
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            //padding: '20px',
          },
          rounded: {
            borderRadius: '5px',
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: paletteEffective.text.primary,
            paddingLeft: '24px', 
            paddingRight: '24px', 
            paddingTop: '7px',
            paddingBottom: '7px',
          }
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            '& [class*="MuiListItem-button"]': {
              color: paletteEffective.text.secondary,
              paddingRight: '10px',
              '& [class*="MuiListItemText-primary"]': {
                fontSize: '14px !important',
                fontWeight: '400 !important',
              },
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: paletteEffective.action.hover,
              },
              '&.activeLink': {
                color: paletteEffective.text.primary,
                backgroundColor: paletteEffective.action.hover,
              }
            },
            [`& .${listItemClasses.root}`]: {
              paddingTop: '4.5px',
              paddingBottom: '4.5px',
              paddingLeft: '15px',
            },
            [`& .${svgIconClasses.root}`]: {
              fontSize: '1.2rem',
            },
            [`& .${listItemIconClasses.root}`]: {
              minWidth: '30px',
              color: paletteEffective.text.secondary,
            },
            [`& .activeLink .${listItemIconClasses.root}`]: {
              color: paletteEffective.text.primary + ' !important',
            }
          },
          paper: {
            //backgroundColor: '#f9f9fb',
            //idea: #f8f9fb
          },
          paperAnchorDockedLeft: {
            borderRight: '1px solid ' + darken('#f1f1f3',0.04),
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: '4px',
            //border: '1px solid #d9d9d9',
            //border: `1px solid ${paletteEffective.divider}`,
            //boxShadow: '0 3px 6px 0 rgb(0 0 0 / 5%)',//customProps.popoverBoxShadow,

            //boxShadow: '0 15px 35px 0 rgb(48 48 92 / 9%), 0 5px 15px 0 rgb(0 0 0 / 8%)', //0 0 0 1px rgb(135 150 170 / 8%)
            //boxShadow: '0 15px 35px 0 rgb(163 207 246 / 6%), 0 5px 15px 0 rgb(0 0 0 / 6%)',

            boxShadow: '0 15px 35px 0 rgb(201 218 233 / 8%), 0 5px 15px 0 rgb(0 0 0 / 5%)',
            border: `1px solid ${paletteEffective.divider}`,
           
            
            
            
            //border: '1px solid #e9e9e9',
          },
          root: {
            '&.MuiPaper': {
              border: 'none',
              elevation2: {
      
              }
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '4px',
            [`& .${outlinedInputClasses.notchedOutline}, &:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .${outlinedInputClasses.notchedOutline}`]: {
              border: '1px solid #dcdce7',
              borderColor: '#dcdce7',
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderWidth: '1px',
            },
          },
          input: {
            [`&:not(textarea):not(.${outlinedInputClasses.inputSizeSmall})`]:{ //only single line input
              padding: '12px 14px',
            } 
          },
        }
      },
/*       MuiSelect: {
        styleOverrides: {
          select: {
            [`&.${inputBaseClasses.inputSizeSmall}`]: {
              paddingTop: '4.4px',
              paddingBottom: '4.4px',
            },
          }
        },
      }, */
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: '5px 0px 5px',
            '&::placeholder': {
              color: '#5d6d8a',
            },
          },
          inputSizeSmall: {
            paddingTop: '10.5px',
            paddingBottom: '10.5px',
          },
        }
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderRadius: '0px',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            borderBottom: '0px',
          },
          input: {
            padding: '10px 12px 10px',
          },
          underline: {
            '&:before': {
              borderBottom: '0px',
            },
            '&:hover:before': {
              borderBottom: '0px',
            },
          },
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '0.75rem',
            //lineHeight: '1.8',
            fontWeight: '400',
            borderRadius: '8px',
            backgroundColor: '#1f2832'
          },
          arrow: {
            "&:before": {
              backgroundColor: '#1f2832'
            }
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            '&.Mui-error': {
              fontSize: '11px',
            },
          }
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: '#eceff2',
          },
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '& [class*="MuiTabs-indicator"]': {
              backgroundColor: typography.h3.color
            }
          },
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            color: paletteEffective.greyDark.main,
            fontSize: typography.h3.fontSize,
            '&[class*="MuiTab-textColorInherit"]': {
              opacity: 1,
              color: paletteEffective.greyDark.main,
            },
            '&[class*="MuiTab-selected"],&[class*="Mui-selected"]': {
              color: typography.h3.color  + ' !important',
            },
            '& [class*="MuiTouchRipple-root"]': {
              display: 'none'
            },
          },
        }
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        variants: [
          {
            props: { variant: "contained", hoverInvert: true },
            shouldForwardProp: [],
            style: {
              transform: 'translateY(1px)', //because of outline
              outline: `1px solid ${paletteEffective.primary.main}`,
              transition: 'all 0.2s linear',
              '&:not([disabled]):hover':{
                backgroundColor: 'rgba(0,0,0,0.0) !important',
                background: 'rgba(0,0,0,0.0) !important',
                outline: `1px solid ${paletteEffective.primary.main}`,
                color: darken(paletteEffective.primary.main,0.15),
                //height: 'calc(100% - 2px)'
              },
            }
          },
          {
            props: { shadow: "true" },
            style: {
              boxShadow: '0 10px 20px -10px '+paletteEffective.primary.main,
              '&:hover':{
                  boxShadow: '0 10px 20px -10px '+paletteEffective.primary.main + ' !important',
              },
            }
          },
          {
            props: { size: "extraLarge" },
            style: { fontSize: '17px', padding: "14px 24px", }
          },
          {
            props: { size: "extraLarge", variant: "outlined" },
            style: { fontSize: '17px', padding: "12px 24px", height: '60px' }
          },
          {
            props: { variant: "contained", color: "greyLight" },
            style: {
              //color: paletteEffective.text.primary,
              //color: '#5f5f5f',//'#444',//theme.palette.getContrastText(theme.palette.grey[300]),
              backgroundColor: '#eff2f5',
              "&:not([disabled]):hover": {
                backgroundColor: '#d5d5d5',
                boxShadow: 'none',
              }
            }
          },
          {
            props: { variant: "contained", color: "primaryGradientLinear" },
            style: {
              '&:not([disabled])': {
                background: paletteEffective.primaryGradientLinear.main??paletteEffective.primary.main,
              },
              '&:not([disabled]):hover': {
                background: paletteEffective.primaryGradientLinear.main??paletteEffective.primary.main,
                opacity: '0.9',
                boxShadow: 'none',
              }
            }
          },
          {
            props: { variant: "outlined", color: "greyLight" },
            style: {
              borderColor: '#d0d4da',
              boxShadow: '0 1px 2px 0 rgb(17 24 38 / 6%)',
              '&:hover': {
                borderColor: 'rgba(0, 0, 0, 0.4)',
              }
            }
          },
          {
            props: { variant: "outlined", color: "greyDark" },
            style: {
              borderColor: '#d0d4da',
              backgroundColor: '#fff',
              boxShadow: '0 1px 2px 0 rgb(17 24 38 / 6%)',
              '&:hover': {
                borderColor: 'rgba(0, 0, 0, 0.4)',
              }
            }
          },
          {
            props: { variant: "text", color: "greyDark" },
            style: {
              '&:not([disabled]):hover': {
                color: paletteEffective.greyDark.main,
                backgroundColor: '#eff2f5',
              }
            }
          },
        ],
        styleOverrides: {
          root: {
            textTransform: 'none',
            boxShadow: 'none',
            fontWeight: '400',
            fontSize: '14px',
            padding: '7px 16px',
            whiteSpace: 'nowrap',
            borderRadius: '5px',
          },
          outlined: {
            //boxShadow: '0 1px 2px 0 rgb(17 24 38 / 6%)',
            fontWeight: '500',
            '&,&:hover': {
              borderWidth: '1px',
            }
          },
          outlinedPrimary: {
            color: darken(paletteEffective.primary.main,0.1),
            borderColor: lighten(paletteEffective.primary.main,0.3),
            '&:hover': {
              border: `1px solid ${paletteEffective.primary.main}`,
              backgroundColor: paletteEffective.primary.main,
              color: paletteEffective.primary.contrastText,
            }
          },
          contained: {
            '&:not([disabled])': {
              '&:hover': {
                boxShadow: 'none',
              },
            },
            fontWeight: '500',
            [`& .${buttonClasses.startIcon}.${buttonClasses.iconSizeSmall}`]: {
              //marginRight: '2px'
            },
            padding: '8px 16px', //4px + 1px missing border
            '&[disabled]': {
              backgroundColor: '#eff2f5',
            },
            color: themeConfigBase?.button?.color
          },
          containedPrimary: {
            backgroundColor: 'inherit',
            //boxShadow will add 1px optical height top and bottom
            //boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, '+paletteEffective.primary.main+' 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(66, 66, 86, 0.08) 0px 2px 5px 0px',
            '&:not([disabled])': {
              background: paletteEffective.primary.main,
              '&:hover': {
                  backgroundColor: lighten(paletteEffective.primary.main,0.07),
                  //boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, '+paletteEffective.primary.main+' 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(66, 66, 86, 0.08) 0px 4px 10px 0px, rgba(66, 66, 86, 0.08) 0px 2px 5px 0px', //null//'none',
              }
            }
          },
          text: {
            fontSize: '14px',
            fontWeight: '500',
            '&:hover': {
              color: darken(paletteEffective.primary.main, 0.1),
              backgroundColor: 'rgb(212 228 255 / 36%)',
            },
            padding: '7px 16px',
            [`& .${buttonClasses.startIcon}`]: {
              fontSize: '12px',
            }
          },
          sizeLarge: {
            paddingTop: '10px',
            paddingBottom: '10px',
            fontSize: '16px',
            height: '48px',
          },
          sizeSmall: {
            fontSize: '0.82rem',
            [`& .${buttonClasses.startIcon}`]: {
              marginRight: '4px',
              '& svg': {
                fontSize: '14px',
              },
            },
          },
          textSizeSmall: {
            padding: '3px 11px',
  /*           [`& .${buttonClasses.startIcon}`]: { //removed-on-mui5-migration
              '& >*:first-child': {
                marginRight: '3px'
              }
            } */
          },
          containedSizeSmall: {
            fontWeight: '500',
            padding: '3px 11px',
          },
          containedSizeLarge: {
            paddingTop: '10px',
            paddingBottom: '10px',
          },
          outlinedSizeSmall: {
            padding: '2px 11px',
  /*           [`& .${buttonClasses.startIcon}`]: { //removed-on-mui5-migration
              '& >svg:first-of-type': {
                fontSize: '17px',
              }
            } */
          },
          outlinedSizeLarge: {
            paddingTop: '11px',
            paddingBottom: '11px',
            height: '48px',
            fontSize: '15px',
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          contained: {
            boxShadow: 'none',
          }
        } 
      },
      MuiTimelineConnector: {
        styleOverrides: {
          root: {
            backgroundColor: '#e9e9e9'
          }
        }
      },
      MuiTimelineContent: {
        styleOverrides: {
          root: {
            padding: '2px 16px'
          }
        }
      },
    },
    customProps: {
      inputBorder: '1px solid #dcdce7',
      inputBorderRadius: '4px',
      boxShadow: {
        boxShadow: 'rgba(0, 0, 0, 0.14) 0px 9px 22px',
      },
      hoverBoxShadow: {
        boxShadow: 'rgba(0, 0, 0, 0.14) 0px 9px 22px',
        transition: 'box-shadow 500ms ease 0s, opacity 500ms ease 0s, z-index 0ms ease-out 500ms',
      },
      menuBoxShadow: {
        //boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 6px, rgba(0, 0, 0, 0.05) 0px 10px 20px',
        boxShadow: customProps.popoverBoxShadow,
      },
      tableHeaderFontProps: {
        color: 'rgb(99, 115, 129)',
        fontWeight: '500',
        fontSize: '14px',
        fontFamily: typography.allVariants.fontFamily,
      },
      dashboardCardStyle: {
        boxShadow: 'rgb(81 100 112 / 9%) 0px 8px 30px 0px',
        borderRadius: '20px',
      },
      outlinedCardStyle: {
        border: '1px solid #f0f2f6',
        boxShadow: '0 4px 16px 0 rgb(25 55 101 / 4%)',
      },
      centeredPaperLayoutPaperStyle: {
        //boxShadow: 'rgb(81 100 112 / 9%) 0px 8px 30px 0px',
        boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 6px',
        borderRadius: '5px',
        //border: '1px solid #e4e7f2'
      },
      popoverPaperStyle: {
        boxShadow: customProps.popoverBoxShadow,
        borderRadius: '3px',
      }
    },
  }


}