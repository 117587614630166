import makeStyles from '@mui/styles/makeStyles';
import Head from "next/head";
import { FunctionComponent } from 'react';

const useStyles = makeStyles(()=>({
    root: {
        backgroundColor: '#005fff',
        backgroundImage: 'linear-gradient(to top right,#005fff,#73a5fb)',
        width: '100%',
        height: '100%',
    }
}));


export const BackgroundGradient = ({children}) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {children}
        </div>
    );

}


export const BackgroundGradientBody = () => {
    return (
        <>
            <Head>
                <style
                    dangerouslySetInnerHTML={{
                    __html: `
                            body {
                                background-color: #005fff;
                                background-image: linear-gradient(to top right,#005fff,#73a5fb);
                                background-repeat: no-repeat;
                                background-attachment: fixed;
                            }
                        `,
                    }}
                >
                </style>
            </Head>
        </>
    );
}

export const BackgroundLightGreyBody = () => {
    return (
        <>
            <Head>
                <style
                    dangerouslySetInnerHTML={{
                    __html: `
                            body {
                                background-color: #fafbfb;
                            }
                        `,
                    }}
                >
                </style>
            </Head>
        </>
    );
}

export interface HtmlBodyStylesProps {
    background?: string
    fixed?: boolean
    overflowHidden?: boolean
    height?: string
    minHeight?: string
}

export const HtmlBodyStyles: FunctionComponent<HtmlBodyStylesProps> = ({background, fixed, overflowHidden, height, minHeight}) => {
    
    let stylesStr = ''
    if (fixed) { stylesStr += 'background-attachment: fixed;' }

    let overflowHiddenStr = overflowHidden ? 'html, body { overflow: hidden; }':null

    return (
            <Head>
                <style
                    dangerouslySetInnerHTML={{
                    __html: `
                            body {
                                ${background?'background: '+background+';':''};
                                ${height?'height: '+height+';':''}
                                ${minHeight?'minHeight: '+minHeight+';':''}
                                ${stylesStr}
                            }
                            ${overflowHiddenStr}
                        `,
                    }}
                >
                </style>
            </Head>
    )
}

export const HtmlBodyOverflowHidden = () => {
    return (
            <Head>
                <style
                    dangerouslySetInnerHTML={{
                    __html: `
                        html, body { overflow: hidden; }
                        `,
                    }}
                >
                </style>
            </Head>
    )
}

export const BackgroundWithTexture = () => {
    return (
        <>
            <Head>
                <style
                    dangerouslySetInnerHTML={{
                    __html: `
                            body {
                                background-color: rgb(233 241 252 / 35%) !important;
                                background-image: radial-gradient(rgb(255 255 255 / 45%) 70%, transparent 5%), radial-gradient(rgb(255 255 255 / 43%) 70%, transparent 5%) !important;
                                background-size: 900px 700px, 500px 400px  !important;
                                background-position: bottom -300px left -400px, bottom -210px left 240px !important;
                                background-repeat: no-repeat !important;
                                background-attachment: fixed !important;
                            }
                        `,
                    }}
                >
                </style>
            </Head>
        </>
    );
}

export const BodyBackgroundLandingGradientWave = () => {
    return (
        <Head>
            <style
                dangerouslySetInnerHTML={{
                     //background-color: #fff !important;
                __html: `
                        body {
                            position: relative;
                            background-image: linear-gradient(to bottom, #cee5ff, #eaf4ff, #f8fbff, #ffffff 1100px) !important;
                            background-repeat: no-repeat !important;
                            background-position: 0px 0px;
                            background-size: auto 1400px;
                        }
                        body:before {
                            content: "";
                            position: absolute;
                            left: 0px;
                            top: -40px;
                            height: 80%;
                            width: 100%;
                            background-image: url(/backgrounds/bg-wave-rotated-2.svg) !important;
 
                            background-repeat: no-repeat !important;
                            background-size: 110% auto;
                            background-position: 0px 40px;
                            opacity: 0.7;
                            z-index: -1;
                        }
                    `,
                }}
            >
            </style>
        </Head>
    );
}


export const BodyBackgroundLandingGradient = ({height}) => {

    height = height || '110vh'

    return (
        <Head>
            <style
                dangerouslySetInnerHTML={{
                     //background-color: #fff !important;
                __html: `
                        body {
                            position: relative;
                            background-repeat: no-repeat !important;
                            background-position: 0px 0px;
                            background-size: auto 1400px;
                        }
                        body:before {
                            content: "";
                            position: absolute;
                            left: 0px;
                            top: -40px;
                            height: ${height};
                            width: 100%;
                            background-color: #fbfafa;
 
                            background-repeat: no-repeat !important;
                            background-size: 110% auto;
                            background-position: 0px 40px;
                            opacity: 0.7;
                            z-index: -1;
                        }
                    `,
                }}
            >
            </style>
        </Head>
    );
}