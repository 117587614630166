import { useTranslation } from "next-i18next";
import Head from "next/head";
import { useMemo, useState, createContext, useContext, useEffect } from "react";
import { isHavingValue, notNullNotUndefined } from "utils/objectUtils";

const YoioContext = createContext(null)

export const useYoioContext = () => {
  return  useContext(YoioContext)
}

export type YoioContextApiProps = {
   setWorkspaceMember?: (value: boolean) => void
}

const YoioContextApiContext = createContext<YoioContextApiProps>(null)

export const useYoioContextApi = () => {
  return useContext(YoioContextApiContext)
}

const environment = process.env.NEXT_PUBLIC_ENVIRONMENT

export const YoioContextProvider = ({children, value}) => {

    const { i18n } = useTranslation()

    const [state, setState] = useState(value)

    const getEffectiveValue = (value) => {
      const effective = value ? {...value} : {}
      effective.pageSettings = effective.pageSettings || {}
  
      if (!isHavingValue(effective.pageSettings.loadMe)) {
          effective.pageSettings.loadMe = true;
      }
  
      if (!isHavingValue(effective.pageSettings.loadMeLazy)) {
          effective.pageSettings.loadMeLazy = false;
      }
  
      if (!isHavingValue(effective.pageSettings.embedded)) {
          effective.pageSettings.embedded = false;
      }

      effective.environment = environment
      effective.locale = i18n?.language || null
      return effective
    }

    useEffect(()=>{
        if (state !== value) {
          setState(value)
        }

    },[value])

    useEffect(()=>{
      // Make available in window
      const effective = getEffectiveValue(state)

      console.debug('yoioContext changed')

      window.yoioContext = window.yoioContext ? 
        {
          ...window.yoioContext,
          ...effective
        }
      :
        {
          ...effective
        }
    },[state])

    const effective = getEffectiveValue(state)
    
    const api = useMemo(() => ({
      setWorkspaceMember: (val)=>{
        notNullNotUndefined(val)
        if (state?.pageSettings?.isWorkspaceMember === val) {
          // Nothing to do
          return;
        }

        console.debug('setWorkspaceMember')

        setState(state=>{
          const updated = {...state}
          updated.pageSettings = {
            ...updated.pageSettings,
            isWorkspaceMember: val,
          }
          return updated
        })
      }
    }), [state]) as YoioContextApiProps

  return (
    <YoioContextApiContext.Provider value={api}>
        <YoioContext.Provider value={effective}>
          <Head>
            <meta name="yoio:yoioContext" content={effective ? JSON.stringify(effective) : ''} />
          </Head>
          {children}
        </YoioContext.Provider>
    </YoioContextApiContext.Provider>
  )

}