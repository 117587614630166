import { Box } from "@mui/material";
import { FunctionComponent, PropsWithChildren } from "react";

export interface LayoutScreenContentCenteredProps {
    // Not exactly centered vertically, but a bit above
    smoothPosition?: boolean
}

export const LayoutScreenContentCentered: FunctionComponent<PropsWithChildren<LayoutScreenContentCenteredProps>> = ({children, smoothPosition}) => {

    const rootStyle = {height: '100vh'};
    const innerStyle = {} as any;
    if (smoothPosition === true) {
        innerStyle.marginTop = '-10vh'
    }

    return (
        <Box display="flex" justifyContent="center" alignItems="center" style={rootStyle}>
            <Box textAlign="center" style={innerStyle}>
                {children}
            </Box>
        </Box>
    )

}