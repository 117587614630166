import { notNullNotUndefined } from 'utils/objectUtils';
import { isEmpty, notEmpty } from '../../../../utils/stringUtils';
import { setCookie, getCookie } from '../cookie/cookie';

/**
 *  Saves an JavaScript object on client side
 */

const cookiePrefix = 'oz_app_';

export const saveObject = (id, object) => {
  const name = getCookieNameForId(id);
  setCookie(name, encodeURIComponent(JSON.stringify(object)));
};

export const getObject = (id) => {
  const name = getCookieNameForId(id);
  const cookieAsString = getCookie(name);
  if (isEmpty(cookieAsString)) {
    return null;
  }
  return JSON.parse(decodeURIComponent(cookieAsString));
};

const getCookieNameForId = (id) => cookiePrefix + id;


export const saveInSessionStorage = (id, obj) => {
  if (obj === null) {
    window.sessionStorage.removeItem(id)
    return
  }
  window.sessionStorage.setItem(id, encodeURIComponent(JSON.stringify(obj)));
}

export const getFromSessionStorageById = (id) => {
  let storedValue = window.sessionStorage.getItem(id);
  if (!storedValue) {
      return null;
  }
  return JSON.parse(decodeURIComponent(storedValue));
}

export const saveInLocalStorage = (id, obj) => {
  if (obj === null) {
    window.localStorage.removeItem(id)
    return
  }
  window.localStorage.setItem(id, encodeURIComponent(JSON.stringify(obj)));
}

export const removeFromLocalStorage = (id) => {
  notEmpty(id)
  window.localStorage.removeItem(id)
}

export const getFromLocalStorageById = (id) => {
  let storedValue = window.localStorage.getItem(id);
  if (!storedValue) {
      return null;
  }
  return JSON.parse(decodeURIComponent(storedValue));
}