import { useCallback, useEffect, useRef, useState } from "react";

export const usePrevious = (value) => {

    const ref = useRef()

    useEffect(() => {
      ref.current = value
    }, [value])

    return ref.current
}

export const useInterval = (callback, delay) => {

  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback])


  useEffect(() => {
    function tick() {
      //@ts-ignore
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay])
}