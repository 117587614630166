import { requireValue } from "modules/picasso-modules/utils/validateUtils";
import { isHavingValue, notNullNotUndefined } from "./objectUtils";

export const getLocation = (href) => {
    notNullNotUndefined(href)

    var match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    return match && {
        href: href,
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7]
    }
}

export const getLocationFromUrlWithoutHost = (href) => {
    notNullNotUndefined(href)

    var match = href.match(/^([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    return match && {
        href: href,
        pathname: match[1],
        search: match[2],
        hash: match[3]
    }
}

export const paramsToQueryString = (obj) => {
    if (obj === null || obj === undefined) throw new Error('obj cannot be null or undefined')
    var str = []
    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
          const val = obj[p]
          if (isHavingValue(val)) {
            str.push(encodeURIComponent(p) + "=" + objectToURIncodedParamValue(val))
          }
      }
    }
    return str.join("&")
}

const objectToURIncodedParamValue = (obj) => {
  if (Array.isArray(obj) && obj.length > 0) {
    if (typeof obj[0] === 'object') {
      // Array of objects, pass as array
      return encodeURIComponent('[')+obj.map(arrEntry=>objectToURIncodedParamValue(arrEntry)).join(',')+encodeURIComponent(']')
    } else {
      // Array of string as comma separated list
      return obj.map(arrEntry=>encodeURIComponent(arrEntry)).join(',')
    }
  }
  else if (typeof obj === 'object') {
    return encodeURIComponent(JSON.stringify(obj))
  } 
  else if (obj !== undefined) {
    return encodeURIComponent(obj)
  }
}

export const getPathname = (url) => {
  notNullNotUndefined(url)
  if (url.length === 0) {
    return ''
  }
  return '/'+url.replace(/^[a-zA-Z]{3,5}:\/{2}[a-zA-Z0-9_.:-]+\//, '')
}

export const buildUrlWithParameterAppended = (url, param, value) => {
  requireValue(url)
  requireValue(param)
  requireValue(value)

  const urlObj = new URL(url);

  // Use URLSearchParams for easy manipulation of query parameters
  const params = new URLSearchParams(urlObj.search);

  // Check if the parameter already exists
  if (params.has(param)) {
      throw new Error(`Parameter '${param}' already exists in the URL.`);
  }

  // Append the new parameter
  params.append(param, value);

  // Return the updated URL
  return urlObj.origin + urlObj.pathname + '?' + params.toString() + urlObj.hash;
}

export const isAbsoluteUrl = (url: string): boolean => {
  requireValue(url)
  // A regular expression to check for protocols (like http, https, ftp, etc.)
  return /^(?:[a-z]+:)?\/\//i.test(url);
}

export const buildAbsoluteUrlWithCurrentHost = (relativeUrl) => {
  requireValue(relativeUrl)

  // using window.location.href here looks odd, but is correct
  const urlObj = new URL(relativeUrl, window.location.href);

  // Use URLSearchParams for easy manipulation of query parameters
  const params = new URLSearchParams(urlObj.search);

  // Return the updated URL
  return urlObj.origin + urlObj.pathname + '?' + params.toString() + urlObj.hash;
}