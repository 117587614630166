import { notNullNotUndefined } from "./objectUtils"
import { capitalizeFirstLetter } from "./stringUtils"

export const populateThemeColors = (style, theme) => {
    notNullNotUndefined(style)
    notNullNotUndefined(theme)
    const updated = {...style}

    if (updated.background && theme?.palette?.[updated.background]) {
        updated.background = theme.palette[updated.background].main
    }

    return updated
}

export const getThemeStyles = (theme, component, variant, color, size) => {
    notNullNotUndefined(theme)
    
    let styles = theme.components?.[component]?.variants?.find(v=>{
        return v.props.variant===variant&&v.props.color===color&&(size===undefined||v.props.size===size)
    })?.style ?? {}

    if (size) {
        styles = {
            ...styles,
            ...theme.components?.MuiButton?.styleOverrides?.['size'+capitalizeFirstLetter(size)]
        }
    }

    return { ...styles }
}

export const toPx = (value:string) => {
    if (value == null || value == undefined) {
        return value
    }
    if (value.endsWith('px')) {
        return value
    }
    return value+'px'
}

export const pxAsNumber = (value:string|number):number => {
    if (value == null || value == undefined) {
         //@ts-ignore
        return value
    }
    if (typeof value === 'number' && isFinite(value)) {
        return value
    }
    //@ts-ignore
    if (typeof value === 'string' || value instanceof String) {
        if (value.endsWith('px')) {
            return parseInt(value.substring(0, value.length-2))
        } else {
            //@ts-ignore
            return parseInt(value)
        }
    } else {
        throw new Error('unexpected type')
    }
}

export const alignToFlexJustify = (align) => {
    if (!align) {
        return align
    }
    return {left: 'flex-start', center: 'center', right: 'flex-end'}[align]
}

/**
 * 
 * {hex}-{stop},{hex}-{stop} to css linear greadient 
 * 
 * E.g. #eaeaeae-30,#fbfbfb-100 to linear-gradient(deg, #eaeaeae 30%, #fbfbfb 100%)
 * 
 * @param str 
 * @returns 
 */
export const strToGradientLinear = (str, deg) => {
    notNullNotUndefined(str)
    notNullNotUndefined(deg)
    const defs = gradientStrToDefs(str)
    return `linear-gradient(${deg}deg, ${defs.map(d=>`${d[0]} ${d[1]}%`).join(',')})`
}

export const gradientStrToDefs = (str) => {
    const defs = str.split(',')
    return defs.map(d=>{ const args = d.split('-'); return args; })
}