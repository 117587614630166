import { getFromLocalStorageById, getFromSessionStorageById, saveInLocalStorage, saveInSessionStorage } from "modules/yoio-modules/storage/clientSideStore/clientSideStore";
import { IssuerId } from "modules/yoio/YoioTypes";
import { nowEpochSeconds } from "utils/dateUtils";
import { isHavingValue, notNullNotUndefined } from "utils/objectUtils";

let magicKey = null;
let apiKey = null;

export const setMagicKey = (value) => {
  let changed = magicKey !== value;

  magicKey = value

  if (changed === true) { //magic key change is like a sign in or sign out. therefore call clearAuthenticatedUser
    clearAuthenticatedUser()
  }
};

export const setApiKey = (value) => {
  let changed = apiKey !== value;

  apiKey = value

  if (changed === true) { //magic key change is like a sign in or sign out. therefore call clearAuthenticatedUser
    clearAuthenticatedUser()
  }
};

export const setAuthenticatedUser = (me) => {
  //console.debug('setAU me', me)
  notNullNotUndefined(me?.accessContext?.credentialType)

  const value = {}

  value.expirationTime = createExpirationTime()
  value.me = { ...me }

  delete value.me.reloadMe;
  delete value.me.reloadGuestIdentity

  try {
    saveInLocalStorage('access_user_credentialType', me.accessContext.credentialType)
    saveInSessionStorage('access_user', value)
  } catch (error) {
    console.debug('error set au')
  }

};

export const clearAuthenticatedUser = () => {
  console.debug('clearAU')
  try {
    saveInLocalStorage('access_user_credentialType', null)
    saveInSessionStorage('access_user', null)
  } catch (error) {
    console.debug('error clearAU')
  }
}

export const setLastAuthenticatedUser = (me) => {
  notNullNotUndefined(me)
  notNullNotUndefined(me.accessContext)
  notNullNotUndefined(me.accessContext.account)
  notNullNotUndefined(me.accessContext.credential)

  saveInLocalStorage('access_user_last', {
    userId: me.accessContext.account.userId,
    credential: {
      issuerId: me.accessContext.credential.issuerId,
      exp: me.accessContext.credential.exp,
    },
  })
}

export const getLastAuthenticatedUser = () => {
  try {
    return getFromLocalStorageById('access_user_last')
  } catch (error) {
    return null;
  }
}

export const clearLastAuthenticatedUser = () => {
  saveInLocalStorage('access_user_last', null)
}


const getCredentialType = () => {
  try {
    return getFromLocalStorageById('access_user_credentialType')
  } catch (error) {
    console.log('cannot check ct')
    return null;
  }
}

export const getAuthenticatedUser = () => {
  try {
    return getFromSessionStorageById('access_user')
  } catch (error) {
    console.log('cannot get au')
    return null;
  }
}

/* export const updateAuthenticatedUserExpirationTime = () => {
  let current = getAuthenticatedUser()
  notNullNotUndefined(current)
  setAuthenticatedUser({
    ...current,
    expirationTime: createExpirationTime()
  })
} */

const createExpirationTime = () => new Date().getTime() + (process.env.NEXT_PUBLIC_ACCESS_SESSION_REFRESH_AFTER_SECONDS * 1000)

const isExpired = () => {
  const user = getAuthenticatedUser()
  
  notNullNotUndefined(user)
  notNullNotUndefined(user.expirationTime)

  return new Date().getTime() > user.expirationTime
}



export const getMagicKey = () => magicKey;

export const getApiKey = () => apiKey;

/**
 * just checks cookie existance, does not validate content!
 */
export const seemsToBeSignedIn = () => {
  return seemsToBeSignedInAsFirebaseUser() || seemsToBeSignedInAsYoioUser()
}

export const seemsToBeSignedInAsFirebaseUser = () => {
  return getCredentialType() === 'SESSION'
}

export const seemsToBeSignedInAsYoioUser = () => {
  return getCredentialType() === 'JWT_SESSION_1'
}

export const needsToSaveCache = () => {
  return !isHavingValue(getAuthenticatedUser())
}

export const canRelyOnCache = () => {
  if (!seemsToBeSignedIn()) {
    return false;
  }

  const user = getAuthenticatedUser()
  return isHavingValue(user?.me?.accessContext) && !isExpired()
}

export const canRefreshCredential = () => {
  // Check if user was authenticated
  const wasOrIsAuthenticated = getLastAuthenticatedUser() != null && getLastAuthenticatedUser().userId
  if (!wasOrIsAuthenticated) {
    return false;
  }
  // Check if token is refreshable
  if (!isAccessTokenRefreshable()) {
    return false;
  }

  return true;
}

const isAccessTokenRefreshable = () => {
  const lastAuthUser = getLastAuthenticatedUser()
  return lastAuthUser != null && lastAuthUser.credential?.issuerId == IssuerId.YOIO
}

export const isCredentialExpiredOrExpiresSoon = () => {
  const lastAuthUser = getLastAuthenticatedUser()
  return lastAuthUser != null && lastAuthUser.credential?.exp && (nowEpochSeconds()+3600) > lastAuthUser.credential?.exp
}

let isRefreshing = false

export const setRefreshingCredential = (val)=>{
  isRefreshing = val
}

export const isRefreshingCredential = () => {
  return isRefreshing
}