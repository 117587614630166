import { notNullNotUndefined } from "utils/objectUtils"

let terminalKey = null

export const setCurrentTerminalKey = (value: string) => {
    notNullNotUndefined(value)
    terminalKey = value
}

export const clearCurrentTerminalKey = () => terminalKey = null

export const getCurrentTerminalKey = ():string => terminalKey