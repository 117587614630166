import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import initFirebase from './firebase/initFirebase';
import { useRouter } from 'next/router';
import { apiSignIn, apiSignInAlias, apiSignOut, getMe } from 'modules/yoio/api/accessApi';
import { useApp } from 'modules/yoio/useApp';
import { isHavingValue, isNullOrUndefined, notNullNotUndefined } from 'utils/objectUtils';
import { canRefreshCredential, canRelyOnCache, clearAuthenticatedUser, clearLastAuthenticatedUser, getAuthenticatedUser, getLastAuthenticatedUser, isCredentialExpiredOrExpiresSoon, isRefreshingCredential, seemsToBeSignedIn, setAuthenticatedUser, setLastAuthenticatedUser, setMagicKey, setRefreshingCredential } from './currentUser';
import alerts from 'modules/yoio/state/alerts';
import { useIsMounted } from 'modules/picasso-ui/form/useIsMounted';
import { useYoioContext } from 'modules/yoio/useYoioContext';
import { MeContext } from './MeContextProvider';
import { useSWRConfig } from 'swr';
import { useWindowEvent } from 'modules/picasso-ui/state/useWindowEvent';
import { AccessEvent, IssuerId } from 'modules/yoio/YoioTypes';
import { createClientSideApiBaseUrl } from 'apiClient/utils';
import axios from 'axios';
import { notify } from 'modules/yoio/errorsService';
import { clearWaitingRequestsQueue, processWaitingRequestsQueue } from 'apiClient/config/apiAxiosConfig';
import { useInterval } from 'modules/picasso-modules/utils/reactUtils';
import { YoioHttpRequestFields } from 'modules/yoio/model/YoioHttpRequestFields';



/**
 * When false then the effect is:
 *  - useAuthEnabled is always false
 *  - useSignedIn is always true
 *  - useHasPermission is always true
 *
 * Effect on auth components:
 *  - <PermissionBorder> always shows children (because useHasPermission is always true)
 *  - <SignOutButton> will be hidden because it makes no sense when there is no auth
 *
 */
let authEnabled = true;

export const useAuthEnabled = () => {
  return authEnabled;
};

const initAuth = (authDomain) => {
  notNullNotUndefined(authDomain)

  if (authEnabled === true) {

    //Firebase Auth
    return initFirebase(authDomain).then((firebaseApp)=>{
      return import('firebase/auth').then((mod)=>{
        return mod.getAuth(firebaseApp)
      })
    })
    
  } else {
    return Promise.resolve(null)
  }

};

const firebaseAuthData = { loaded: false };

export const useSignedIn = () => {

  const { pageSettings } = useYoioContext()

  const meContextVal = useContext(MeContext);

  const { me, signedIn } = meContextVal;

  const [hasAccount, setHasAccount] = useState(null)
  const [hasPrincipalIdentity, setHasPrincipalIdentity] = useState(null)
  const [probablySignedIn, setProbablySignedIn] = useState()
  const [hasWorkspaceContext, setHasWorkspaceContext] = useState(null)

  if (meContextVal.loaded === false) {
    if (meContextVal.lazyLoad) {
      meContextVal.lazyLoad();
    }
  }

  /**
   * Events
   */

  useEffect(()=>{
    if (pageSettings.loadMe === false) {
      setProbablySignedIn(false);
      return;
    }
      
    setProbablySignedIn(seemsToBeSignedIn())
  }, [])

  useEffect(()=> {
    if (me) {
      setHasAccount(isHavingValue(me.accessContext?.accountId))
      setHasPrincipalIdentity(isHavingValue(me?.accessContext?.identity) && me?.accessContext?.identity?.identityType === 'PRINCIPAL')
      setHasWorkspaceContext(isHavingValue(me.accessContext?.workspace))
    }
  }, [me])

  const getAccount = () => {
    if (me?.accessContext?.account) {
      return me.accessContext.account;
    }
    return null;
  };

  const identityAnon = () => {
    let identity = me?.accessContext?.identity;

    return identity && identity.identityType === 'ANON';
  };

  const hasPermission = (permission) => {
    notNullNotUndefined(permission)
    const result = me?.accessContext?.effective?.authorities?.find(a=>a.authority===permission);
    return result !== undefined && result !== null;
  }

  const userAccountIdOrGuestId = me?.accessContext?.effective?.userAccountIdOrGuestId

  const isUsingYoioToken = me?.accessContext?.isUsingYoioToken === true

  const emailConfirmed = isHavingValue(me?.accessContext?.account?.emailConfirmed) ? me.accessContext.account.emailConfirmed : null

  return { 
    signedIn, 
    me, 
    getAccount, 
    identityAnon,
    probablySignedIn, 
    hasPrincipalIdentity, 
    hasAccount, 
    hasWorkspaceContext, 
    hasPermission, 
    userAccountIdOrGuestId, 
    isUsingYoioToken,
    emailConfirmed
  }

}

let firebaseBindingDone = false;


/**
 * 
 * 
 * 
 * @param {*}  
 *    when true, then a sign attempt can be made if there is no signed in user
 *    when false, a sign in attempt will only be done in case there is a signed in user that is expired,
 *         but never on a a user that does not seem to be signed in
 *        (a sign in attempt can also be a refresh attempt, therefore its only done on "seems to be signed in user")
 * @returns 
 */
export const loadMe = ({lazy, signInAllowed, signInForce}) => {

  if (!isHavingValue(lazy)) {
    lazy = false;
  }

  const router = useRouter();

  const { mkey } = router.query;

  const { app } = useApp();

  const isMounted = useIsMounted()

  const [me, setMe] = useState(null);

  const loadMeEnabled = app.useApi; 

  const [meLoading, setMeLoading] = useState(loadMeEnabled ? {} : {meLoaded: true, userLoaded: true}); //if loadMeEnabled===false, then set all like loaded

  const [isLoadingInitially, setIsLoadingInitially] = useState(false)
  const [loadingError, setLoadingError] = useState(false)

  const isUserAccessTokenExpired = (userId) => {
    const lastAuthUser = getLastAuthenticatedUser()
    return lastAuthUser
      && lastAuthUser.credential?.issuerId == IssuerId.YOIO
      && userId !== lastAuthUser.userId
  } 

  const loadMeInitially = () => {
    console.debug('loadMeInitially');
    //dont set setIsLoadingInitially, its only ment to be set when screen should be blocked

    return getMe().then((me) => {
      if (!isMounted()) {
        return;
      }

      handleMeLoaded(me)
      setIsLoadingInitially(false)
    })
    .catch(onMeLoadError);
  }

  const reloadMe = () => {
    //console.debug('reloadMe');
    return getMe().then((me) => {
      if (!isMounted()) {
        return;
      }

      handleMeLoaded(me)
      return me;
    });
  }

  const handleMeLoaded = (me, isFromCache) => {
    setMe(me)
/*     setMe(cur=>{
      if (cur != null && me != null) {
        const meIsEuqal = stableHash(me) === stableHash(cur)
        console.log('meIsEuqal', meIsEuqal)
        return cur;
      }
      return me
    }) */
    setMeLoading({meLoaded: true, userLoaded: true, isFromCache: isFromCache})
  }

  const onMeLoadError = ()=>{
    console.debug('onMeLoadError')
    clearAuthenticatedUser()
    setMe(null);
    setMeLoading({meLoaded: true, userLoaded: true})
    setIsLoadingInitially(false)

    if (!lazy) { //if lazy is true, then the current page is not depending on loading "me", e.g. the landing page. its optional. therefore dont go into error state
      setLoadingError(true)
    }
  }

  let lazyLoadRequested = false;
  const onLoadedCallbacks = [];

  const lazyLoad = (callback) => {

    if (lazyLoadRequested === true) {
      return;
    }

    lazyLoadRequested = true;
    if (callback) {
      if (firebaseAuthData.loaded === true) {
        callback();
      } else {
        onLoadedCallbacks.push(callback);
      }
    }

    if (typeof window === "undefined") {
      lazyLoadRequested = false;
      return;
    }
    if (!seemsToBeSignedIn()) {
      //console.debug'lazyload not seemstobesignedin');
      loadMeInitially().then(()=>{
        lazyLoadRequested = false;
      })
    } else {
      lazyLoadRequested = false;
    }
  }

  let meResultInitial = null;
  if (lazy === true) {
    meResultInitial = {
      me: null,
      signedIn: null,
      user: null,
      loaded: false,
      lazyLoad
    };
  }

  const [meResult, setMeResult] = useState(meResultInitial);


  /**
   * Events
   */

   useInterval(()=>{
    // Refresh credential in background if online

    if (!navigator.onLine) {
      return;
    }

    refreshCredentialIfNecessary(false)
   }, 1000 * 60 * 15) // Every 15 minutes

  useEffect(()=>{
    if (mkey) {
        setMagicKey(mkey);
    } else {
        setMagicKey(null);
    }
}, [mkey]);

  useEffect(()=> {
    if (meLoading.meLoaded !== true) {
      return;
    }
    if (meLoading.userLoaded !== true) {
      return;
    }

    refreshMeResult(meLoading.isFromCache)
  },[meLoading])

  useWindowEvent(AccessEvent.accessTokenRefreshed, ()=>{
    reloadMe()
  })

  const refreshFailedCounter = useRef(0) 

  const refreshMeResult = (isFromCache) => {
    console.debug('refreshMeResult', 'account: '+ me.accessContext.account?.userId)

    if (isUserAccessTokenExpired(me.accessContext.account?.userId)) {
        clearLastAuthenticatedUser() // clear to prevent endless loop
        clearAuthenticatedUser()
        refreshAccessTokenAttemptOrRequireLogin().then(()=>{
          console.debug('refreshed access token')
        })
        .catch(()=>{
          refreshFailedCounter.current++
          console.debug('failed to refresh access token')
          if (refreshFailedCounter.current < 3) {
            reloadMe()
          }
        })
        return;
    }

    let meEffective = {...me};
    meEffective.reloadGuestIdentity = reloadGuestIdentity;
    meEffective.reloadMe = reloadMe;
    let signedIn = isHavingValue(me?.accessContext?.accountId) && isHavingValue(me?.accessContext?.identity);
    setMeResult({me: meEffective, signedIn });

    if (isFromCache !== true) {
      if (me?.accessContext?.credentialType && me.accessContext.credentialType !== 'NONE') { //there is always a 'me', but dont cache if its not signed in
        setAuthenticatedUser(me)

         // Last authenticated user is stored for the purpose of refreshing tokens.
         // Only accounts can get an access token and refresh token (No strict reason. Just how it works at the moment.)
        if (me.accessContext?.account) {
          setLastAuthenticatedUser(me) // This one is set in 'if', but not cleared in 'else' to keep the last known user info. Only on logout.
        }
      } else {
        clearAuthenticatedUser()
      }
    }
  }

  const handleFirebaseAuthData = () => {
    console.debug('handleFirebaseAuthData')

    const {isMounted, user} = firebaseAuthData;

    if (!user) {
      if (isMounted) {
        clearAuthenticatedUser()
        return loadMeInitially()
      }
      return;
    }

    if (isMounted) {

      let additionalConfig = {};
      if (lazy) {
        additionalConfig.supressErrorMessageIfAccessError = true
      }

      user.getIdToken(true).then((token) => {
        const authHeader = 'Bearer ' + token

        let headers = {}
        headers.Authorization = authHeader;

        apiSignIn(additionalConfig, headers).then(() => {
          return loadMeInitially()
        })
        .catch(onMeLoadError)

      })

    }

  } 

  const lastFocusCheck = useRef(null)

  const refreshCredentialIfNecessary = (useApiCallCheck) => {

    if (!canRefreshCredential()) {
      return;
    }

    if (isCredentialExpiredOrExpiresSoon()) {
      console.debug('session seems expire (reason: exp time).')
      refreshAccessTokenAttemptOrRequireLogin()
    } else if (useApiCallCheck) {
      // If the UI does not think the token is expired, make a call to the API to see if the credential is still accepted.
      // The UI can't see on it's own if the token is 'really' there.
        
        // Check if the user still has access
        getMe('tCheck').then((me)=>{
          if (me.accessContext.account?.userId !== getLastAuthenticatedUser().userId) {
            // This can happen if the session cookie is not present anymore
            console.debug('session seems expired (reason: api response)')
            refreshAccessTokenAttemptOrRequireLogin()
          }
        })

    }
  }

  const onFocus = () => {
    /**
     * This check does not only check if the 'exp' date of the current credential passed,
     * but also if the API still recogniozes the user (useApiCallCheck=true).
     */
    const useApiCallCheck = lastFocusCheck.current === null || lastFocusCheck.current+1000*5 < Date.now()
    if (useApiCallCheck) {
      lastFocusCheck.current = Date.now()
    }
    refreshCredentialIfNecessary(useApiCallCheck)
  }

  useEffect(()=>{
    window.addEventListener('focus', onFocus, false)
    return ()=>{
      window.removeEventListener('focus', onFocus)
    }
  },[])

  useEffect(()=> {
    //console.debug'auth');

    if (authEnabled !== true) { //global flag; always true at the moment. should be replaced by app level.
      return;
    }
    //console.debug'authEnabled');

    if (loadMeEnabled !== true) {
      return;
    }
    //console.debug'loadMeEnabled');

    if (app?.useApi !== true) { //loadMeEnabled actually already checks this. however, can stay here.
      return;
    }
    //console.debug'useApi enabled');

    if (signInForce === true) {
      console.log('signInForce', signInForce)
      //console.debug'signInForce');
      //get fresh session from provider. actually that is only firebase, should be "signInForceFirebase"
      handleSignInWithFirebase();
      return;
    }

    // Check if UI knows based on cached data, that the access token expired (99% case).
    // Does not check here, if UI token is really expired. That happens in refreshMeResult, because it needs to load 'me' anyways, so it will check if its still the same user.
    if (canRefreshCredential() && isCredentialExpiredOrExpiresSoon()) {
      refreshAccessTokenAttemptOrRequireLogin(true).catch(()=>{
        console.log('failed')
        return continueLoginFlow()
      })
      return;
    }

    if (seemsToBeSignedIn()) {
      //console.debug'seemsToBeSignedIn and not expired');

      if (canRelyOnCache()) {//from session storage cache, so that content can already be painted

        //console.debug'auth relying on cache');
        handleMeLoaded(getAuthenticatedUser().me, true);

        //reload me afterwards. if user is not authenticated, this will clear his session etc.
        reloadMe()
        return
      }

      if (!lazy) {
        setIsLoadingInitially(true)
      }
      loadMeInitially()
      return

    }

    continueLoginFlow()
        
    return
  },[]);

  const continueLoginFlow = () => {
    if (lazy && !seemsToBeSignedIn()) {
      //console.debug('lazy and not signed in')
      //if lazy and the user appears not to be signed in then dont continue, so that firebase etc is not initialised
      //because if lazy the login is optional on that page
      return
    }

    if (!seemsToBeSignedIn()) { //---> currently this is where the logic goes if user is signed in with JWT SESSION. therefore firebase is not called
      //console.debug'!seemsToBeSignedIn');
      if (signInAllowed !== true) {
        if (!lazy) {
          setIsLoadingInitially(true)
        }
        loadMeInitially()

        return
      }
    }

    console.log('ue01')

    handleSignInWithFirebase();
  }


    const handleSignInWithFirebase = () => {
        //console.debug'handleSignInWithFb');
              //------ sign in via firebase
        // here it goes if either there is a user with an expired session or there is "no user and signInAllowed is true"

        //Firebase Auth
        if (!firebaseBindingDone) {
          firebaseBindingDone = true;
            setIsLoadingInitially(true)
            initAuth(app.browserAuthDomain).then((auth)=>{

              return import('firebase/auth').then((mod)=>{
                
                mod.onAuthStateChanged(auth, function (user) {
  
                  //console.debug'user', user)
    
                  firebaseAuthData.user = user;
                  firebaseAuthData.isMounted = true;
                  firebaseAuthData.loaded = true;
      
                  ////console.debug'firebaseAuthData', firebaseAuthData);
      
                  if (lazy === true) {
                    if (lazyLoadRequested === true) {
                      handleFirebaseAuthData();
                      if (onLoadedCallbacks.length > 0) {
                        onLoadedCallbacks.forEach(fnc=>fnc())
                      }
                    }
                  } else {
                    handleFirebaseAuthData();
                  }
      
                  return;
                });

              })

            })
            return;
        } else {
          if (lazy === true) {
            if (lazyLoadRequested === true) {
              handleFirebaseAuthData();
              if (onLoadedCallbacks.length > 0) {
                onLoadedCallbacks.forEach(fnc=>fnc())
              }
            }
          } else {
            setIsLoadingInitially(true)  //already set this earlier
            handleFirebaseAuthData();
          }
        }
    }

  const reloadGuestIdentity = () => {
    console.debug('reloadGuestIdentity')
    refreshMeResult()
  }

  return { meResult, reloadGuestIdentity, isLoadingInitially, loadingError };
  
};

let axiosInstanceForRefresh = null

export const useSignOut = () => {
  
  const router = useRouter();

  const { me } = useSignedIn()

  const { cache } = useSWRConfig()

  const { app } = useApp()

  const handleSignOut = () => {
    return initAuth(app.browserAuthDomain).then(()=>{
      clearAuthenticatedUser()
      clearLastAuthenticatedUser()

      return apiSignOut().then(()=>{
        console.debug('apiSignOut success');
    
        return import('firebase/auth').then((mod)=>{

          return mod.getAuth().signOut().catch(function () {
            //console.debug'firebase auth sign out failed');
            console.debug('frb auth signout fail')
          }).then(()=>{
            //console.debug'firebase auth sign out successful');
            console.debug('frb auth signout success', 'me having value: ' + isHavingValue(me))
            if(me && me.reloadMe) {
              me.reloadMe();
            }
          });

        })
    
      })

    })
  }

  const signOutAndRedirect = () => {
    console.debug('signOutAndRedirect');
    return handleSignOut().then(() => {
      let target = {
        pathname: '/',
      };
      console.debug('signOutAndRedirect redirect');
      return router.push(target).then(()=>{
        console.debug('signOutAndRedirect done');
        return cache.clear()

      });
    });
  };

  return { signOutAndRedirect };
};

export const useHasPermission = (permission) => {

  const { hasPermission } = useSignedIn()
  
  return hasPermission(permission)
}

export const useIsInGroup = (group) => {
  const { me } = useSignedIn();

  const [isInGroup, setIsInGroup] = useState(null);

  useEffect(() => {
    if (me === undefined) {
      return;
    }
    if (me === null) {
      return;
    }
    if (!me.accessContext) {
      return;
    }
    if (me.accessContext?.effective.groups.includes(group)) {
      setIsInGroup(true);
    } else {
      setIsInGroup(false);
    }
  }, [me]);

  return { isInGroup };
};


export const useSignInAlias = () => {

  const router = useRouter()

  const { me } = useSignedIn()

  const signIn = (data, signInSuccessUrl) => {

    return apiSignInAlias(data)
    .then(()=>{
      return me.reloadMe().then(()=>{
        console.log('redirect ' + signInSuccessUrl)
        router.push(signInSuccessUrl)
      })
    })
    .catch((e)=> {
      if (e?.message?.includes('401')) {
        alerts.error('Wrong user or password')
      } else {
        console.error(e)
        alerts.error('Unknown error')
      }
      throw e
    })

  }

  return { signIn }

}

let refreshingPromise = null

export const refreshAccessToken = (expirySeconds, fail) => {

  if (refreshingPromise) {
    return refreshingPromise
  }

  let params = null
  if (expirySeconds) {
    params = params || {}
    params.expirySeconds = expirySeconds
  }
  if (fail) {
    params = params || {}
    params.fail = fail
  }

  if (!axiosInstanceForRefresh) {
    axiosInstanceForRefresh = axios.create({
      withCredentials: true,
      baseURL: createClientSideApiBaseUrl(),
    })
    axiosInstanceForRefresh.interceptors.request.use((config)=>{
      config.headers = config.headers || {}
      const lastAuthenticatedUser = getLastAuthenticatedUser()
      if (lastAuthenticatedUser?.userId) {
        config.headers[YoioHttpRequestFields.HEADER_LAST_USER] = lastAuthenticatedUser.userId
      } else {
        config.headers[YoioHttpRequestFields.HEADER_LAST_USER] = 'unknown'
      }
      return config
    })
  }

  const promise = axiosInstanceForRefresh.post('/access/refresh', null, { params })
  if (refreshingPromise) {
    return refreshingPromise
  }
  refreshingPromise = promise
  setRefreshingCredential(true)


  console.debug('Refresh AT start')
  
  return promise.then((res)=>{
      console.debug('Refresh AT done')
      setRefreshingCredential(false) //first
      refreshingPromise = null
      processWaitingRequestsQueue() //first
      window.dispatchEvent(new Event(AccessEvent.accessTokenRefreshed))
      return res
  }).catch(error=>{
    setRefreshingCredential(false)
    refreshingPromise = null
    clearWaitingRequestsQueue()
    throw error
  })
} 

  /**
   * Will refresh access token.
   * If refresh succeeds, refreshAccessToken() will trigger a me reload.
   * If refresh fails, an userLoginRequired event will be dispatched
   * @returns 
   */
export const refreshAccessTokenAttemptOrRequireLogin = (forwardAllErrors) => {
  return refreshAccessToken().catch((error)=>{
    if (error.response?.status === 401) {
      window.dispatchEvent(new Event(AccessEvent.userLoginRequired))
      if (forwardAllErrors) {
        throw error;
      } else {
        return;
      }
    }
    error.message = 'unexpected error while refreshing token ' + (error.message || '')
    notify(error)
    throw error
  })
}