import { Box, LinearProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { LayoutScreenContentCentered } from "components/layout/positioning/LayoutScreenContentCentered";
import { BackgroundLightGreyBody } from "modules/picasso-ui/layout/BackgroundGradient";


const useStyles = makeStyles(()=>({
    colorPrimary: {
        backgroundColor: '#a8b6cd',
      },
    barColorPrimary: {
        backgroundColor: '#e4ebf3',
    }
}))

export const AppLoadingState = () => {

    const classes = useStyles()

    return (
        <>
            <BackgroundLightGreyBody />
            <LayoutScreenContentCentered>
                <Box style={{opacity: '0.5'}}>
                    {/* <AppLogoSmall watermark={true} linkDisabled={true} /> */}
                </Box>
                <Box color="text.secondary" width="200px">
                    <LinearProgress 
                        classes={{
                            colorPrimary: classes.colorPrimary, 
                            barColorPrimary: classes.barColorPrimary
                        }}
                    />
                </Box>
            </LayoutScreenContentCentered>
        </>
    )

}