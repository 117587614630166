
const setMessage = (updated) => {
  onMessageChanged(updated);
};

const onMessageChanged = (message) => {
  if (message.type === 'quotaWithUpgradeProposal') {
    let event = new Event('myapp.messageQuota');
    event.value = message;
    window.dispatchEvent(event);
    return;
  }


  let event = new Event('myapp.message');
  event.value = message;
  window.dispatchEvent(event);
};

const success = (text) => {
  setMessage({ severity: 'success', text });
}

const error = (text, duration) => {
  setMessage({ severity: 'error', text, duration });
}

const errorPermanent = (text) => {
  setMessage({ severity: 'error', text, duration: 100000 });
}

export default { success, error, setMessage };
